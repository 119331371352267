import s from "./Header.module.css";
import profil from "../../assets/images/profil.png";
import arrow from "../../assets/images/arrow.png";
import { Link, animateScroll as scroll } from "react-scroll";
import logo from "../../assets/images/logo.png";

export function Header() {
  return (
    <header className={s.container}>
      <div className={s.profil}>
        {/* <h1 className={s.title}>ROMAIN DUBUS</h1> */}
        <img src={logo} alt="logo" className={s.logo} />
      </div>

      <div className={s.subtitle}>
        <h2>QUI SUIS-JE ?</h2>
        <p>
          Développeur web passionné, je suis fullstack dans l'écosystème
          JavaScript (React, Node.js, Express, MongoDB, PostgreSQL, ...). Je
          suis également un grand fan de l'open source et de la communauté
          JavaScript.
        </p>
        {/* mettre un link vers cardList au clic sur img */}
        <Link
          activeClass="active"
          to="CardList"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          <img src={arrow} alt="arrow" className={s.arrow} />
        </Link>
      </div>
    </header>
  );
}
