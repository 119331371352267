import s from "./App.module.css";
import React, { useState, useEffect } from "react";
import { Header } from "./components/Header/Header";
import { CardList } from "./components/CardList/CardList";
import { Footer } from "./components/Footer/Footer";
import supabase from "./supabase";
import { Auth } from "./components/Auth/Auth";

export function App() {
  const [cards, setCards] = useState([]);

  // get cards from supabase

  useEffect(() => {
    const fetchCards = async () => {
      const { data, error } = await supabase.from("cards").select("*");
      if (error) {
        console.log(error);
      } else {
        setCards(data);
      }
    };
    fetchCards();
  }, []);

  return (
    <div className={s.app}>
      <Header />
      <CardList cards={cards} setCards={setCards} />
      {/* <Auth /> */}
      <Footer />
    </div>
  );
}
