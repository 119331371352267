import s from "./CardListItem.module.css";

export function CardListItem({ card, setcards }) {
  return (
    <a className={s.aCard} href={`${card.url}`} target="_blank">
      <li className={s.cardListItem} key={card.id}>
        <h2>{card.title}</h2>
        <p>{card.description}</p>
      </li>
    </a>
  );
}
