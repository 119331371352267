import s from "./style.module.css";
import { ContactForm } from "../ContactForm/ContactForm";
import React, { useState, useEffect } from "react";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAIUDCRGO_faGTLcEEuolHnBFBq3LP3dxg",
  authDomain: "auth-test-eaa43.firebaseapp.com",
  projectId: "auth-test-eaa43",
  storageBucket: "auth-test-eaa43.appspot.com",
  messagingSenderId: "329888702621",
  appId: "1:329888702621:web:af66a3b3036861f11b3474",
  measurementId: "G-GDM9NN9DW3",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth();
const provider = new GoogleAuthProvider();

export function Auth() {
  const [user, setUser] = useState(null);

  // auth with google

  const handleGoogleLogin = async () => {
    const { user } = await signInWithPopup(auth, provider);
    setUser(user);
  };
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });
    return unsubscribe;
  }, []);

  return (
    <>
      {user ? (
        <div className={s.container}>
          <ContactForm />
          <div className={s.user}>
            <h2>{user?.displayName}</h2>
            <img src={user?.photoURL} alt="" />
            <button className={s.sign} onClick={() => signOut(auth)}>
              Déconnexion
            </button>
          </div>
        </div>
      ) : (
        <div className={s.container}>
          <button className={s.sign} onClick={handleGoogleLogin}>
            Connexion avec Google
          </button>
        </div>
      )}
    </>
  );
}
