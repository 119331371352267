import s from "./CardList.module.css";
import { CardListItem } from "../CardListItem/CardListItem";

export function CardList({ cards, setcards }) {
  if (cards.length === 0) {
    return (
      <section>
        <p>Il n'y a pas de projets à afficher.</p>
      </section>
    );
  }
  return (
    <section className={s.container} id="CardList">
      <h2>Mes projets</h2>
      <ul className={s.cardList}>
        {cards.map((card) => (
          <CardListItem key={card.id} card={card} setcards={setcards} />
        ))}
      </ul>
    </section>
  );
}
