import s from "./Footer.module.css";
import github from "../../assets/images/github-mark.png";
import linkedIn from "../../assets/images/logo-linkedin.png";

export function Footer() {
  return (
    <footer className={s.container}>
      <h2>Contactez-moi</h2>
      {/* Liens vers LinkedIn et Github avec logo */}
      <div className={s.social}>
        <a
          href="https://www.linkedin.com/in/romain-dubus-a59895213/"
          target="_blank"
        >
          <img src={linkedIn} alt="LinkedIn" />
        </a>
        <a href="https://github.com/Cooltor" target="_blank">
          <img src={github} alt="Github" />
        </a>
        {/* <a href="../../assets/fichiers/RDubusCV.pdf" className={s.cv}>
          CV
        </a> */}
      </div>
    </footer>
  );
}
